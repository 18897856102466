/* eslint-disable */

<template>
  <div class="vx-row Lgx202142297964412928">
    <div class="vx-col w-full">
      <vx-card class="mb-6">
        <div class="flex flex-col gap-y-4">
          <div class="rounded-lg bg-light main-border" v-for="item in currentPage === 0 ? table_items : table_items_p2" :key="item.id">
            <div class="mb-3 p-4" style="border-bottom: 1px solid #181d2a">
              <h4 class="font-semibold">{{ item.description }}</h4>
            </div>

            <div class="flex pb-2 px-4 flex-row justify-between w-full">
              <div>
                <h5 class="text-dark">Potential Target</h5>
                <p>{{ item.target }}</p>
              </div>

              <div class="flex gap-x-4" style="width: 75%">
                <div class="flex flex-col" style="flex-basis: 35%">
                  <v-select
                    placeholder="Select an attack from the dropdown"
                    class="w-full"
                    :options="attack_options"
                    v-model="item.attack_selected"
                  />
                  <small v-if="item.attack_is_correct !== null && item.attack_is_correct === false" class="ml-1 text-danger font-semibold"
                    >This answer is invalid</small
                  >
                  <small v-if="item.attack_is_correct !== null && item.attack_is_correct === true" class="ml-1 text-success font-semibold"
                    >This answer is correct</small
                  >
                </div>
                <div class="flex flex-col" style="flex-basis: 65%">
                  <v-select
                    placeholder="Select an action from the dropdown"
                    class="w-full"
                    :options="action_options"
                    v-model="item.action_selected"
                  />
                  <small v-if="item.action_is_correct !== null && item.action_is_correct === false" class="ml-1 text-danger font-semibold"
                    >This answer is invalid</small
                  >
                  <small v-if="item.action_is_correct !== null && item.action_is_correct === true" class="ml-1 text-success font-semibold"
                    >This answer is correct</small
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>

      <vx-card class="mb-32">
        <div class="flex items-center justify-between">
          <p class="font-semibold text-lg">Page: {{ currentPage + 1 }} / 2</p>
          <vs-button
            class="shadow-lg"
            color="success"
            icon="icon-arrow-right"
            icon-pack="feather"
            icon-after
            v-if="currentPage === 0"
            @click="changePage('next')"
            >Next Page
          </vs-button>
          <vs-button class="shadow-lg" color="danger" icon="icon-arrow-left" icon-pack="feather" v-else @click="changePage('previous')"
            >Previous Page
          </vs-button>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import Prism from 'vue-prism-component';
import shuffle from 'shuffle-array';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),

      currentPage: 0,

      update_index: 0,

      table_items: [
        {
          id: 1,
          description: 'Hacker is injecting malicious java script in a vulnerable web server and wants to steal cookies from your machine.',
          target: 'Web Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 1,
          action_correct: 1,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 2,
          description: 'Attacker is changing the DNS entries to redirect you from a legitimate URL to a fake URL',
          target: 'DNS Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 2,
          action_correct: 2,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 3,
          description: 'A hacker is sending thousands of SYN Packets from multiple random sources',
          target: 'Web Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 3,
          action_correct: 3,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 4,
          description: 'This attack involves using IP spoofing and the ICMP to saturate a target network with traffic.',
          target: 'Network Router',
          attack_selected: null,
          action_selected: null,
          attack_correct: 4,
          action_correct: 4,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 5,
          description: 'Attacker uses IP packets to ping a target system with an IP size over the maximum of 65,535 bytes.',
          target: 'Remote Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 5,
          action_correct: 5,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 6,
          description: 'The hacker remotely establishes a connection that permits him to execute remote commands from his machine. ',
          target: 'End User',
          attack_selected: null,
          action_selected: null,
          attack_correct: 6,
          action_correct: 6,
          attack_is_correct: null,
          action_is_correct: null,
        },
      ],
      table_items_p2: [
        {
          id: 7,
          description:
            'An attacker intercepts and saves old messages and then tries to send them later, impersonating one of the participants.',
          target: 'Receiver',
          attack_selected: null,
          action_selected: null,
          attack_correct: 7,
          action_correct: 7,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 8,
          description:
            'It is a self-propagating attack, does not require a host, targets a SQL database and is also looking to exploit default credentials of the database server.',
          target: 'MySQL Database Server',
          attack_selected: null,
          action_selected: null,
          attack_correct: 8,
          action_correct: 8,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 9,
          description:
            'Attacker takes the time to conduct research into targets and creates email messages that are personal and relevant. ',
          target: 'Targeted end user',
          attack_selected: null,
          action_selected: null,
          attack_correct: 9,
          action_correct: 9,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 10,
          description:
            'The hacker is targeting C-level exec with a hardware device to monitor the input activity and capture the login credentials',
          target: 'C-Level Executive',
          attack_selected: null,
          action_selected: null,
          attack_correct: 10,
          action_correct: 10,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 11,
          description: 'Hackers look for insecure websites and plant a malicious script into HTTP or PHP code on one of the pages. ',
          target: 'Target Users',
          attack_selected: null,
          action_selected: null,
          attack_correct: 11,
          action_correct: 1,
          attack_is_correct: null,
          action_is_correct: null,
        },
        {
          id: 12,
          description: 'Hacker is inserting hidden access in a software application which is designed to skip accounts login',
          target: 'Target Application',
          attack_selected: null,
          action_selected: null,
          attack_correct: 12,
          action_correct: 11,
          attack_is_correct: null,
          action_is_correct: null,
        },
      ],

      attack_options: [
        { id: 1, label: 'Cross Site Scripting (XSS)' },
        { id: 2, label: 'DNS Poisoning' },
        { id: 3, label: 'Botnet' },
        { id: 4, label: 'Smurf Attack' },
        { id: 5, label: 'Ping of Death' },
        { id: 6, label: 'RAT' },
        { id: 7, label: 'Session Replay Attack' },
        { id: 8, label: 'Worm' },
        { id: 9, label: 'Spear Phishing' },
        { id: 10, label: 'Keylogger' },
        { id: 11, label: 'Watering Hole Attacks' },
        { id: 12, label: 'Backdoor' },
      ],

      action_options: [
        { id: 1, label: 'Enable Input Validation' },
        { id: 2, label: 'Use DNSSEC for DNS Integrity' },
        { id: 3, label: 'Activate DDoS Protection' },
        { id: 4, label: 'Disable IP-directed broadcasts at the routers' },
        { id: 5, label: 'Use a firewall to check fragmented IP packets for maximum size.' },
        { id: 6, label: 'Disable Remote Access Services on the System' },
        { id: 7, label: 'Use session timestamps or nonce (a random number or a string that changes with time).' },
        { id: 8, label: 'Change the default application passwords on the SQL database.' },
        { id: 9, label: 'Analyze email headers to look for email spoofing.' },
        { id: 10, label: 'Activate 2FA or MFA and use push alert using OTP' },
        { id: 11, label: 'Execute a code review on the application server.' },
      ],
    };
  },
  methods: {
    changePage(val) {
      if (val === 'next') {
        this.currentPage++;
      }
      if (val === 'previous') {
        this.currentPage--;
      }
    },
    markSimulation() {
      let totalScore = 0;

      this.table_items.forEach((x, i) => {
        if (x.attack_selected && x.attack_selected.id === x.attack_correct) {
          this.table_items[i].attack_is_correct = true;
          totalScore++;
        } else {
          this.table_items[i].attack_is_correct = false;
        }
        if (x.action_selected && x.action_selected.id === x.action_correct) {
          totalScore++;
          this.table_items[i].action_is_correct = true;
        } else {
          this.table_items[i].action_is_correct = false;
        }
      });

      this.table_items_p2.forEach((x, i) => {
        if (x.attack_selected && x.attack_selected.id === x.attack_correct) {
          this.table_items_p2[i].attack_is_correct = true;
          totalScore++;
        } else {
          this.table_items_p2[i].attack_is_correct = false;
        }
        if (x.action_selected && x.action_selected.id === x.action_correct) {
          totalScore++;
          this.table_items_p2[i].action_is_correct = true;
        } else {
          this.table_items_p2[i].action_is_correct = false;
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    vSelect,
    shuffle,
  },
  created() {
    this.attack_options = shuffle(this.attack_options);
    this.action_options = shuffle(this.action_options);
    this.table_items = shuffle(this.table_items);
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>
<style>
.Lgx202142297964412928 {
  font-size: 15px;
}

.theme-dark .v-select .vs__dropdown-toggle {
  background: #02050d;
}

.theme-dark .v-select .vs__dropdown-menu {
  background: #02050d;
}

.theme-dark .v-select .vs__dropdown-menu::-webkit-scrollbar {
  height: 8px;
}

.theme-dark .vx-card input {
  background: #02050d;
}
</style>
